// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//require("jquery")
//require("jquery-migrate.min")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
//const fonts = require.context('../fonts', true)
//const imagePath = (name) => fonts(name, true)


//import '../../assets/images/mallorca/mallorca_2.jpg'

//import '../fonts/*'
//import 'custom.js'

/*************
require("./lib/jquery/jquery-migrate.min")
require("./lib/bootstrap/js/bootstrap.bundle.min")
require("./lib/easing/easing.min")
require("./lib/superfish/hoverIntent")
require("./lib/superfish/superfish.min")

require("./lib/wow/wow")
require("./lib/waypoints/waypoints.min")
require("./lib/counterup/counterup.min")
require("./lib/owlcarousel/owl.carousel")
************/

// require("./lib/isotope/isotope.pkgd")
// require("./lib/isotope/isotope.pkgd.min")
// require("./lib/lightbox/js/lightbox.min")
// require("jquery-touchswipe")
// require("./lib/touchSwipe/jquery.touchSwipe.min")

//require("./contactform/contactform")

/*
require("./js/main")
require("./js/custom")
*/
//require('css/site')
import '../css/site'
